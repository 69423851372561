<template>
  <div class="mb-4">
    <div ref="summernote"></div>
  </div>
</template>

<script>
import $ from 'jquery';
window.$ = window.jQuery = $; // Make jQuery globally available
import 'summernote/dist/summernote-lite.css'
import 'summernote/dist/summernote-lite.js'

import initSummernoteLang from './es.js'
initSummernoteLang($); // Initialize the language with jQuery


import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'



export default {
  name: 'SummernoteEditor',
  props: {
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      uploadedImages: new Set(),
    }
  },
  mounted() {
    const defaultOptions = {
      lang : 'es-ES',
      
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['insert', ['link', 'picture', 'video']],
        ['view', ['fullscreen', 'codeview', 'help']],
      ],
      placeholder: 'Ingresar Descripción',
      tabsize: 4,
      height: 200,
      callbacks: {
        onChange: this.onChange,
        onBlur: this.onBlur,
        onImageUpload: this.uploadImage, // Handle image uploads
      },
    }

    $(this.$refs.summernote).summernote({
      ...defaultOptions,
      ...this.options,
    })

    // Set initial content
    $(this.$refs.summernote).summernote('code', this.value)

    //getCurrentImagePaths
    this.uploadedImages = this.getCurrentImagePaths(this.value)
    console.log(this.uploadedImages)
  },
  watch: {
    value(newVal) {
      const currentContent = $(this.$refs.summernote).summernote('code')
      if (newVal !== currentContent) {
        $(this.$refs.summernote).summernote('code', newVal)
      }
    },
  },
  beforeDestroy() {
    // delete unused images before component is destroyed
    this.deleteUnusedImages()

    // Clean up Summernote instance before component is destroyed
    //$(this.$refs.summernote).summernote('destroy')

    console.log('Summernote instance destroyed')
  },
  methods: {
    update(value){ //getCurrentImagePaths
      this.uploadedImages = this.getCurrentImagePaths(value)
    },
    onChange(contents) {
      // Emit the 'input' event to update the parent component's v-model
      this.$emit('input', contents)
    },
    onBlur() {
      // Optional: emit an event when the editor loses focus
      this.$emit('blur')
    },
    uploadImage(files) {
      const file = files[0]
      if (!file) return

      const storage = getStorage()
      const storagePath = `tasks/${Date.now()}_${file.name}`
      const storageRef = ref(storage, storagePath)

      const uploadTask = uploadBytesResumable(storageRef, file)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Optional: Monitor upload progress
        },
        (error) => {
          // Handle upload errors
          console.error('Image upload failed:', error)
        },
        () => {
          // Upload completed successfully
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // Insert the image into the editor at the current cursor position
            $(this.$refs.summernote).summernote(
              'insertImage',
              downloadURL,
              ($image) => {
                // Add data attribute with storage path
                $image.attr('data-storage-path', storagePath)
                // Add to uploadedImages
                this.uploadedImages.add(storagePath)

                console.log('Image uploaded:', this.uploadedImages)
              }
            )
          })
        }
      )
    },
    getCurrentImagePaths(htmlContent) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(htmlContent, 'text/html')
      const imgElements = doc.querySelectorAll('img[data-storage-path]')
      const imagePaths = new Set()
      imgElements.forEach((img) => {
        const storagePath = img.getAttribute('data-storage-path')
        if (storagePath) {
          imagePaths.add(storagePath)
        }
      })
      return imagePaths
    },
    async deleteUnusedImages() {
      const currentContent = $(this.$refs.summernote).summernote('code')
      const currentImages = this.getCurrentImagePaths(currentContent)
      const imagesToDelete = [...this.uploadedImages].filter(
        (storagePath) => !currentImages.has(storagePath)
      )

      console.log('Images to delete:', imagesToDelete)

      if (imagesToDelete.length === 0) {
        // No images to delete
        return
      }

      const storage = getStorage()
      for (const storagePath of imagesToDelete) {
        const fileRef = ref(storage, storagePath)
        try {
          await deleteObject(fileRef)
          console.log(`Deleted image from storage: ${storagePath}`)
          // Remove from uploadedImages
          this.uploadedImages.delete(storagePath)
        } catch (error) {
          console.error(
            `Failed to delete image at storage path: ${storagePath}`,
            error
          )
        }
      }
    },
    async deleteImagesFromHTML(htmlContent) {
      // This method is now accessible from the parent component
      const imagePaths = this.getCurrentImagePaths(htmlContent)

      if (imagePaths.size === 0) {
        // No images to delete
        return
      }

      const storage = getStorage()

      for (const storagePath of imagePaths) {
        const fileRef = ref(storage, storagePath)
        try {
          await deleteObject(fileRef)
          console.log(`Deleted image from storage: ${storagePath}`)
          // Remove from uploadedImages if necessary
          this.uploadedImages.delete(storagePath)
        } catch (error) {
          console.error(
            `Failed to delete image at storage path: ${storagePath}`,
            error
          )
        }
      }
    },
  },
}
</script>

<style>
.theme--dark .note-editable {
  background-color: #333;
  color: #fff;
  border: 1px solid #444;
  padding: 10px;
}

.note-modal-backdrop {
  z-index: 4 !important;
}

.note-modal-content {
  position: fixed;
}
</style>
